<style scoped></style>
<template>
  <div v-show="isShow">
    <div
      v-if="loadStatus"
      style="width: 100%; padding: 10px 0; text-align: center; color: #795548"
      @click="$emit('refresh')"
    >
      {{ text }}
    </div>
    <div
      v-if="!loadStatus"
      style="width: 100%; padding: 10px 0; text-align: center; color: #795548"
    >
      没有更多数据了
    </div>
  </div>
</template>

<script>
export default {
  name: "loadMoreData",
  data() {
    return {
      scloll: true,
      text: "加载中...",
    };
  },
  props: {
    loadStatus: {
      type: Boolean,
      default: true,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    scrollBottom() {
      var ds = document.body.scrollTop || document.documentElement.scrollTop;
      if (document.body.clientHeight > window.screen.height) {
        this.text = "加载中...";
      }

      if (window.screen.height + ds + 50 >= document.body.clientHeight) {
        if (this.scloll && this.loadStatus) {
          this.$emit("refresh");
          this.scloll = false;
          setTimeout(() => {
            this.scloll = true;
          }, 1000);
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollBottom, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollBottom);
  },
  created() {},
  components: {},
};
</script>
